<template>
  <div id="myproduct">
    <div class="top">
      <span>选择类型:</span>
      <el-select size="small" v-model="status" placeholder="请选择分类">
        <el-option
          v-for="item in options"
          :key="item.cat_id"
          :label="item.name"
          :value="item.cat_id"
        >
        </el-option>
      </el-select>
    </div>
    <template v-if="tableData">
      <el-table
        v-loading="loading"
        :header-cell-style="getRowClass"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column prop="product_id" label="ID" width="120">
        </el-table-column>

        <el-table-column prop="head_img" label="产品图片" width="100">
          <template slot-scope="scope">
            <img
              v-if="scope.row.head_img"
              style="width:40%"
              :src="scope.row.head_img[0]"
              alt=""
            />
            <span v-else>暂无</span>
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="true"
          prop="title"
          label="标题"
          width=""
        >
          <template slot-scope="scope">
            <span @click="add(scope.row.product_id)">

              {{scope.row.title}}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="min_price" label="价格(元)" width="120">
        </el-table-column>

        <el-table-column prop="status" label="店铺操作" width="120">
          <template slot-scope="scope">
           
             <el-button
              v-if="status != 5"
              @click="addshop(scope.row.product_id,true)"
              type="primary"
              size="small"
              >上架到商店</el-button
            >

            <el-button
              v-else
              @click="delmodel(scope.row.product_id,false)"
              type="danger"
              size="small"
              >从商店下架</el-button
            >

         
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          label="商品预览"
          width="120"
          v-if="status == 5"
        >
          <template slot-scope="scope">
            <el-button
              @click="qrcode(scope.row.product_id)"
              type="primary"
              icon="el-icon-shopping-bag-1"
              size="small"
              >预览码</el-button
            >
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          label="市场操作"
          width="160"
          v-if="$store.state.userinfo.type == '供应商'"
        >
          <template slot-scope="scope">
            <el-button
            v-if='scope.row.iftuijian!=1'
              @click="addthis(scope.row.product_id)"
              type="primary"
              size="small"
              >上架到市场</el-button
            >
             
             <template  v-else>
             <el-button
              type="danger"
              size="small"
              @click="detail(scope.row.product_id)"
              >下架</el-button
            >
             <el-button
            
             @click="updated(scope.row.product_id)"
              type="primary"
              size="small"
              >更新</el-button
            >
             </template>
            
          </template>

        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          background
          v-loading="loading"
          layout="prev, pager, next"
          :current-page="page"
          :page-size="10"
          :total="total"
          @current-change="change"
        >
        </el-pagination>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "MyProduct",
  data() {
    return {
      tableData: [],
      loading: true,
      total: 0,
      page: 0,
      status: 5,
      options: [
        {
          name: "已上架",
          cat_id: 5
        },
        {
          name: "未上架",
          cat_id: 0
        },
        {
          name:"自助下架",
          cat_id:11
        }
      ]
    };
  },
  methods: {
    getRowClass({ rowIndex }) {
      if (rowIndex === 0) {
        return "background:#F5F7FA;color:#353535;";
      } else {
        return "";
      }
    },
    //上架/下架 商店
    delmodel(product_id,type){
      this.$confirm('请确认是否下架', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.addshop(product_id,type)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    addshop(product_id,type){
     
       this.axios.get(type?this.api.addshop:this.api.delshop,{
         params:{
           product_id,
         }
       })
       .then((res)=>{
         if(res.code==0){
          this.$message({
            message: res.msg,
            type: "success"
          });
         }else{
                 this.$message({
            message: res.msg,
            type: "warning"
          });
         }
         this.page=1;
         this.getlist();
       })
    },

    // 上架到市场

    addthis(id) {

      this.$store.commit('changeAdd',{id:id,type:1})
      // this.axios
      //   .get(this.api.addsc, {
      //     params: {
      //       product_id: id
      //     }
      //   })
      //   .then(res => {
      //     this.$message({
      //       message: res.msg,
      //       type: "success"
      //     });
      //   });
    },

    // 下架
    detail(source){
      this.$confirm('其他已上架此产品的小店将同步下架，确认下架吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.detailinfo(source)
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },



    updated(product_id) {
      this.axios.get(this.api.update,{
        params:{
          product_id,
        }
      })
      .then((res)=>{
        if(res.code==1){
           this.$message({
            type: 'success',
            message:res.msg
          });
         }else{
           this.$message({
            type: 'info',
            message:res.msg
          });    
         }
      })
    },
    //修改标题和价格

    add(id){
      this.$store.commit("addProductId", id);
      this.$store.commit("alertChange",false);
    },

    detailinfo(source){
       this.axios.get(this.api.detail,{
         params:{
          source,
        }
      })
      .then((res)=>{
         if(res.code==1){
           this.$message({
            type: 'success',
            message:res.msg
          });
         }else{
           this.$message({
            type: 'info',
            message:res.msg
          });    
         }
           this.page=1;
         this.getlist();
      })
    },

    change(val) {
      this.page = val;
      this.getlist();
    },

    getlist() {
      this.axios
        .get(this.api.productlist, {
          params: {
            page: this.page,
            status: this.status
          }
        })
        .then(res => {
          const list = res.spus;
          if (list != "") {
            list.forEach(item => {
              item.min_price = (Number(item.min_price) / 100).toFixed(2);
            });
          }
          this.tableData = list;
          this.total = Number(res.total_num);
          this.loading = false;
        })
        .catch(()=>{
            this.loading = false;
        })
    },
    qrcode(product_id) {
      this.axios
        .get(this.api.qrcode, {
          params: {
            product_id
          }
        })
        .then(res => {
          this.$alert(
            `<img style='width:200px;height:200px;display:block;margin:0 auto' src='${res.path}' >`,
            "商品预览",
            {
              dangerouslyUseHTMLString: true,
              showClose: false
            }
          );
        });
    }
  },
  created() {
    this.page += 1;
    this.getlist();
  },
  watch: {
    status: function() {
      this.tableData = [];
      this.page = 1;
      this.getlist();
    },
    "$store.state.alert.status":function(state){
      
      if(!state){
        
        this.tableData=[];
  
         this.page = 1;
        this.getlist()
      }else{
        console.log(this.page)
      }
    },
  }
};
</script>