<template>
  <div id="order">
    <div id="select">
      <div class="order-top">
        <div class="item">
          <span>订单时间:</span>
          <el-select size="small" v-model="time" placeholder="请选择订单时间">
            <el-option
              v-for="item in timeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <div class="item">
          <span>订单状态:</span>
          <el-select size="small" v-model="status" placeholder="请选择订单状态">
            <el-option
              v-for="item in type"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="list">
        <div class="list-box">
          <template>
            <el-table
              v-loading="loading"
              :header-cell-style="getRowClass"
              :data="tableData"
              border
              
              style="width: 100%"
            >
              <el-table-column width="160" align="center" prop="create_time" label="下单时间" >
              </el-table-column>

              <el-table-column width="80" align="center" prop="head_img" label="产品图片" >
                <template slot-scope="scope">
                  <img
                    v-if="scope.row.order_detail.product_infos[0].thumb_img"
                    style="width:40%"
                    :src="scope.row.order_detail.product_infos[0].thumb_img"
                    alt=""
                  />
                  <span v-else>暂无</span>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                :show-overflow-tooltip="true"
                prop="order_detail.product_infos[0].title"
                label="标题"
                width=""
              >
              </el-table-column>
              <el-table-column

                align="center"
                :show-overflow-tooltip="true"
                prop="laiyuan"
                label="来源"
                width="80"
              >
               <template slot-scope="scope">
                
                
               
                   <el-button v-if="scope.row.laiyuan=='市场'" size='mini' @click="altet(scope.row.source)" type="primary">{{scope.row.laiyuan}}</el-button>
                    <el-button v-else-if="scope.row.laiyuan=='代发'" size='mini' @click="altet(scope.row.source)" type="primary">{{scope.row.laiyuan}}</el-button>
                    <span v-else-if="scope.row.laiyuan=='自有'">{{scope.row.laiyuan}}</span>
                       <a v-else target="_blank" :href="scope.row.source">{{scope.row.laiyuan}}</a>
               </template>
              </el-table-column>

              <el-table-column width="80" align="center" prop="min_price" label="价格(元)" >
                  <template slot-scope="scope">
                     <span>{{(scope.row.order_detail.price_info.order_price/100).toFixed(2)}}</span>
                  </template>
              </el-table-column>

                <el-table-column width="90" align="center" prop="order_detail.delivery_info.address_info.user_name" label="购买人" >
              </el-table-column>

               <el-table-column :show-overflow-tooltip="true" prop="order_detail.delivery_info.address_info" label="地址信息" >
                    <template slot-scope="scope">
                        <span>{{scope.row.order_detail.delivery_info.address_info.province_name+scope.row.order_detail.delivery_info.address_info.city_name+scope.row.order_detail.delivery_info.address_info.county_name+scope.row.order_detail.delivery_info.address_info.detail_info}}</span>
                    </template>
              </el-table-column>

              <el-table-column width="120" align="center" prop="status" label="状态/操作" >
                <template slot-scope="scope">

                  <template v-if="scope.row.laiyuan=='自有'">
                    <div>
                       <el-tag v-if="typeText!='已完成'" type="warning" disable-transitions
                          >{{typeText}}</el-tag>

                        <el-tag v-else type="success" disable-transitions>
                          {{typeText}}</el-tag
                        >
                    </div>
                  </template>

                  <template v-else>
                    <!-- 查看详情 -->
                     <el-button type="primary" size='mini' @click="detail(scope.row.order_id)">查看详情</el-button>
                  </template>
                 
                </template>
              </el-table-column>

             
            </el-table>
          </template>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "Order",
  data() {
    return {
      time: 86400,
      status: 20,
      typeText:"待付款",
      type: [
        {
          name: "待付款",
          id: 10
        },
        {
          name: "待发货",
          id: 20
        },
        {
          name: "待收货",
          id: 30
        },
        {
          name: "已完成",
          id: 100
        },
        {
          name: "取消订单",
          id: 200
        },
        {
          name: "取消付款/超时取消",
          id: 250
        }
      ],
      timeList: [
        {
          name: "24小时内",
          id: 86400
        },
        {
          name: "最近一周",
          id: 604800
        },
        {
          name: "最近一月",
          id: 18144000
        },
        {
          name: "最近三月",
          id: 54432000
        }
      ],
      tableData: [],
      loading: true,
      page: 0
    };
  },
  methods: {
    // 查看详情
    detail(id){
      this.$router.push({
          path: `/orderdetail/${id}`,
      })
    },
    getRowClass({ rowIndex }) {
      
      if (rowIndex === 0) {
        return "background:#F5F7FA;color:#353535;";
      } else {
        return "";
      }
    },
    altet(id){
       this.$alert(
        `<img style='width:200px;height:200px;display:block;margin:0 auto' src='https://web.yqicheng.com/addons/yumi_dianqun/temp/ewm/${id}.png' >`,
        "预览",
        {
          dangerouslyUseHTMLString: true,
          showClose: false,
          
        }
      );
    },
    getlist() {
      this.axios
        .get(this.api.order, {
          params: {
            btime: this.time,
            status: this.status
          }
        })
        .then(res => {
          if(res.code=='-100'){
             window.location.href=this.api.loginout
          }else{
            this.loading=false;
            this.tableData=res;
          }
          
        });
    }
  },
  created() {
 
    if(this.$route.params.id){
      this.type.forEach(element => {
         if(element.id==this.$route.params.id){
           this.typeText=element.name
         }
      });
     this.status=Number(this.$route.params.id)
     this.time=Number(this.$route.params.time)
    }
    
     this.getlist();
  },
  watch:{
    time:function(){
      this.getlist()
    },
    status:function(val){
     

      this.type.forEach(element => {
         if(element.id==val){
           this.typeText=element.name
         }
      });

      this.getlist()
    }
  }
};
</script>